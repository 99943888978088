import tesla from "../../../../content/essays/automation/images/tesla.jpg";
import flow from "../../../../content/essays/automation/images/flow.png";
import ifttt from "../../../../content/essays/automation/images/ifttt.png";
import slack from "../../../../content/essays/automation/images/slack.png";
import waymo from "../../../../content/essays/automation/images/waymo.jpg";
import waymo2 from "../../../../content/essays/automation/images/waymo2.jpg";
import sarco from "../../../../content/essays/automation/images/sarco.jpg";
import * as React from 'react';
export default {
  tesla,
  flow,
  ifttt,
  slack,
  waymo,
  waymo2,
  sarco,
  React
};
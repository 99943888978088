import image1 from "../../../../content/essays/world-of-images/images/image1.png";
import image2 from "../../../../content/essays/world-of-images/images/image2.png";
import image3 from "../../../../content/essays/world-of-images/images/image3.png";
import image4 from "../../../../content/essays/world-of-images/images/image4.png";
import cover from "../../../../content/essays/world-of-images/images/cover.png";
import * as React from 'react';
export default {
  image1,
  image2,
  image3,
  image4,
  cover,
  React
};
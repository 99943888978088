module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-LVPGF72CY3","head":true,"anonymize":true,"respectDNT":true,"exclude":["/admin/**"],"enableWebVitalsTracking":true,"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/vercel/path0/src/components/Layout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-transformer-remark","options":{"plugins":[]}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}},{"resolve":"/vercel/path0/plugins/footnotes.js","options":{"referenceComponent":"Footnote"}}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0"},
    }]

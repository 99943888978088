exports.components = {
  "component---src-aengusm-gatsby-theme-brain-templates-brain-js": () => import("./../../../src/@aengusm/gatsby-theme-brain/templates/brain.js" /* webpackChunkName: "component---src-aengusm-gatsby-theme-brain-templates-brain-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-assemblages-design-like-water-index-mdx": () => import("./../../../src/pages/assemblages/design-like-water/index.mdx" /* webpackChunkName: "component---src-pages-assemblages-design-like-water-index-mdx" */),
  "component---src-pages-assemblages-magical-reasoning-mdx": () => import("./../../../src/pages/assemblages/magical-reasoning.mdx" /* webpackChunkName: "component---src-pages-assemblages-magical-reasoning-mdx" */),
  "component---src-pages-assemblages-optics-mdx": () => import("./../../../src/pages/assemblages/optics.mdx" /* webpackChunkName: "component---src-pages-assemblages-optics-mdx" */),
  "component---src-pages-assemblages-toolmakers-mdx": () => import("./../../../src/pages/assemblages/toolmakers.mdx" /* webpackChunkName: "component---src-pages-assemblages-toolmakers-mdx" */),
  "component---src-pages-essays-jsx": () => import("./../../../src/pages/essays.jsx" /* webpackChunkName: "component---src-pages-essays-jsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-notes-jsx": () => import("./../../../src/pages/notes.jsx" /* webpackChunkName: "component---src-pages-notes-jsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-tags-jsx": () => import("./../../../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-pages-works-jsx": () => import("./../../../src/pages/works.jsx" /* webpackChunkName: "component---src-pages-works-jsx" */),
  "component---src-templates-essay-js": () => import("./../../../src/templates/essay.js" /* webpackChunkName: "component---src-templates-essay-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

